import React from "react"
import { Box, BoxProps } from "@chakra-ui/core"
import Image, { FluidObject } from "gatsby-image"
import { LaptopDownOnly, LaptopUpOnly } from "../../utils/MediaQueries"
import { useIsSideBarOpen } from "../../hooks/useIsSidebarOpen"

export type BannerProps = BoxProps & {
  image?: FluidObject
  mobileImage?: FluidObject
  url?: string | null
  loading?: "auto" | "lazy" | "eager" | undefined
}

const BannerLogic = ({
  image,
  mobileImage,
  url,
  loading = "eager",
  ...props
}: BannerProps) => {
  const isSideBarOpen = useIsSideBarOpen()
  return (
    <>
      {image && mobileImage ? (
        <>
          <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
            <Image
              imgStyle={{ objectFit: "contain" }}
              loading={loading}
              fluid={mobileImage}
            />
          </LaptopDownOnly>
          <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
            <Image
              imgStyle={{ objectFit: "contain" }}
              loading={loading}
              fluid={image}
            />
          </LaptopUpOnly>
        </>
      ) : image ? (
        <Image
          imgStyle={{ objectFit: "contain" }}
          loading={loading}
          fluid={image}
        />
      ) : (
        <></>
      )}
    </>
  )
}

const Banner = ({
  image,
  mobileImage,
  url,
  loading = "eager",
  ...props
}: BannerProps) => {
  const isSideBarOpen = useIsSideBarOpen()
  return (
    <Box width="100%" bg="red" {...props} className="BannerImageSection">
      <a
        css={{ pointerEvents: !url ? "none" : undefined }}
        href={url ? url : ""}
      >
        <>
          {image && mobileImage ? (
            <>
              <LaptopDownOnly isSideBarOpen={isSideBarOpen}>
                <Image
                  imgStyle={{ objectFit: "contain" }}
                  loading={loading}
                  fluid={mobileImage}
                />
              </LaptopDownOnly>
              <LaptopUpOnly isSideBarOpen={isSideBarOpen}>
                <Image
                  imgStyle={{ objectFit: "contain" }}
                  loading={loading}
                  fluid={image}
                />
              </LaptopUpOnly>
            </>
          ) : image ? (
            <Image
              imgStyle={{ objectFit: "contain" }}
              loading={loading}
              fluid={image}
            />
          ) : (
            <></>
          )}
        </>
      </a>
    </Box>
  )
}

export default Banner
