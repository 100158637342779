import React from "react"
import { Box, Flex, SimpleGrid } from "@chakra-ui/core"
import { StringParam, useQueryParam } from "use-query-params"
import { navigate } from "gatsby"
import { FluidObject } from "gatsby-image"
import * as R from "ramda"

import MaxWidthGrid from "../components/Layout/MaxWidthGrid"
import Heading from "../components/typography/Heading"
import Body from "../components/typography/Body"
import CloseSideBar from "../components/CloseSideBar"
import DropDownSelector from "../components/DropDownSelector"
import ContentfulRichText from "../components/ContentfulRichText"
import AccessoryProductCard from "../components/AccessoryProductCard"
import SEOBlock from "../components/SEOBlock"
import SEO from "../components/boilerplate/seo"

import { SortMethodValue, sortMethods, getLowestPrice } from "../utils/sort"
import { ShopifyVariantPricing } from "../shopify/graphql/queries"
import { AccessoryProductListQuery } from "../../graphql-types"
import { bp } from "../utils/MediaQueries"
import useShopifyVariantPricing from "../hooks/useShopifyVariantPricing"
import Banner from "../components/Banner"

export interface AccessoryProductListProps {
  data: AccessoryProductListQuery
  pageContext: {
    pageTitle: string
    pageSlug: string
    accessorySlugs: string[]
  }
  location: Location
}

const AccessoryProductList: React.FC<AccessoryProductListProps> = ({
  data,
  pageContext,
  location,
}) => {
  // Sort
  const [sortParam, setSort] = useQueryParam("sort", StringParam)
  const sort = sortParam ? sortParam : "BY_RELEVANCE"

  // Pricing
  const { pricingMap } = useShopifyVariantPricing(
    R.uniq(
      R.flatten(
        data.accessories.edges.map(({ node }) =>
          node?.variants?.map((variant) => variant?.shopifyId)
        )
      )
    )
  )

  // Default method accessories
  const defaultAccessories = data.accessories.edges.map(({ node }) => ({
    ...node,
    variants: node.variants?.map((variant) => ({
      ...variant,
      pricing:
        (pricingMap[variant?.shopifyId ?? ""] as ShopifyVariantPricing) || null,
    })),
  }))
  // sorted accessories
  let sortedAccessories: any = data?.sortedAccessories?.accessories?.map(
    (accessory: any) => ({
      ...accessory,
      variants: accessory.variants?.map((variant) => ({
        ...variant,
        pricing:
          (pricingMap[variant?.shopifyId ?? ""] as ShopifyVariantPricing) ||
          null,
      })),
    })
  )

  const accessoriesToRender = sortedAccessories || defaultAccessories

  return (
    <>
      <SEO
        title={data.collection?.seoTitle || pageContext.pageTitle}
        description={data.collection?.seoDescription?.seoDescription || ""}
        location={location}
      />
      {data.collection?.banner?.fluid && (
        <Banner
          image={data.collection?.banner?.fluid as FluidObject}
          mobileImage={
            (data.collection?.mobileBanner?.fluid as FluidObject) || undefined
          }
          url={data.collection.bannerSlug}
        />
      )}
      <CloseSideBar>
        <MaxWidthGrid pt={["4rem", null, null, null, "10rem"]}>
          <Flex
            direction="column"
            gridColumn={["1 / 3", null, null, null, "2 / 14"]}
            justifyContent={["center", null, null, null, "flex-start"]}
            paddingLeft={[null, null, null, null, "2rem"]}
          >
            <Heading size="4" fontWeight="bold" color="night" mb="1.5625rem">
              {pageContext.pageTitle}
            </Heading>
            <Flex justify="flex-end">
              <DropDownSelector
                title="Sort by"
                dropDownType="single"
                onOptionSelected={(name: SortMethodValue) => setSort(name)}
                options={
                  [
                    {
                      title: "Relevance",
                      name: "BY_RELEVANCE",
                      selected: sort === "BY_RELEVANCE",
                    },
                    {
                      title: "Price (Low - High)",
                      name: "BY_PRICE_LOW",
                      selected: sort === "BY_PRICE_LOW",
                    },
                    {
                      title: "Price (High - Low)",
                      name: "BY_PRICE_HIGH",
                      selected: sort === "BY_PRICE_HIGH",
                    },
                    {
                      title: "A-Z",
                      name: "BY_A_Z",
                      selected: sort === "BY_A_Z",
                    },
                    {
                      title: "Z-A",
                      name: "BY_Z_A",
                      selected: sort === "BY_Z_A",
                    },
                  ] as {
                    title: string
                    name: SortMethodValue
                    selected: boolean
                  }[]
                }
                showChevron
              />
            </Flex>
          </Flex>
        </MaxWidthGrid>
        <Box
          mt="1.25rem"
          mb="5rem"
          borderBottom="1px solid"
          borderColor="dividerLine"
        />
        <MaxWidthGrid>
          {data.accessories.edges.length <= 0 && (
            <Body
              gridColumn="2/14"
              color="dawn"
              mb={["5rem", null, null, null, "0"]}
            >
              No accessories available
            </Body>
          )}
          <SimpleGrid
            columns={[1, null, null, null, 3, 4]}
            gridColumn={["1/end", null, null, null, "2/14"]}
            marginX={[null, null, null, null, null, "1.25rem"]}
            spacingX="2rem"
            spacingY="3.5rem"
            mb="5rem"
          >
            {sort === "BY_RELEVANCE"
              ? accessoriesToRender.map((acc) => (
                  <AccessoryProductCard
                    key={acc.id}
                    image={acc.productListImage?.fluid as FluidObject}
                    title={acc.title!}
                    price={getLowestPrice(acc.variants)}
                    varaintCount={acc.variants?.length ?? 0}
                    onClick={() => navigate(`/products/${acc.internalTitle}`)}
                  />
                ))
              : accessoriesToRender
                  .sort(sortMethods[sort])
                  .map((acc) => (
                    <AccessoryProductCard
                      key={acc.id}
                      image={acc.productListImage?.fluid as FluidObject}
                      title={acc.title!}
                      price={getLowestPrice(acc.variants)}
                      varaintCount={acc.variants?.length ?? 0}
                      onClick={() => navigate(`/products/${acc.internalTitle}`)}
                    />
                  ))}
          </SimpleGrid>
        </MaxWidthGrid>
        {data.collection?.seoContentBlock && (
          <SEOBlock
            label={`Learn more about ${pageContext.pageTitle}`}
            mt={bp("80", "200")}
            mb="80"
          >
            <Body as="h2" fontWeight="bold" mb="20">
              {pageContext.pageTitle}
            </Body>
            <ContentfulRichText richText={data.collection.seoContentBlock} />
          </SEOBlock>
        )}
      </CloseSideBar>
    </>
  )
}

export default AccessoryProductList
