import React from "react"
import { Box, BoxProps, Collapse, Flex, IconButton } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import GatsbyBackgroundImage from "gatsby-background-image"

import MaxWidthGrid from "../Layout/MaxWidthGrid"
import SmallCaps from "../typography/SmallCaps"

import animations from "../../constants/animations"
import { gatsbyFluidImage } from "../../utils/Storybook"
import { bp } from "../../utils/MediaQueries"
import Embed from "../Embed"
import "./seo-content-block.css";

const defaultImage = gatsbyFluidImage(
  require("../../assets/images/seo-placeholder.png"),
  2.648
)

type SEOBlockProps = BoxProps & {
  image?: FluidObject
  video?: string
  label?: string
}

export default function SEOBlock({
  image = defaultImage,
  video = "",
  label = "Learn more",
  children,
  ...props
}: SEOBlockProps) {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <Box backgroundColor="red" {...props}>
      <GatsbyBackgroundImage fluid={image}>
        <Flex
          w="100%"
          h={bp("30rem", "45.25rem")}
          direction="column"
          align="center"
          justify="flex-end"
          background="linear-gradient(180deg, rgba(44, 46, 52, 0) 0%, rgb(44 46 52 / 0.4) 100%)"
          pb="2.5rem"
        >
          <SmallCaps size="lg" color="white" fontWeight="bold" mb="1.375rem">
            {label}
          </SmallCaps>
          <IconButton
            aria-label="Learn more"
            backgroundColor="white"
            icon="add"
            borderRadius="1000px"
            transition={`300ms transform ${animations.bezier}`}
            transform={isOpen ? "rotate(45deg)" : "rotate(0deg)"}
            onClick={() => setIsOpen(!isOpen)}
          />
        </Flex>
      </GatsbyBackgroundImage>
      <Collapse isOpen={isOpen}>
        {/* <div className="CustomResponsiveEmbed" dangerouslySetInnerHTML={{ __html: video }} /> */}
        {video ? (
          <>
            <MaxWidthGrid className="layout-block layout-block__embed" pt="7.5rem">
              <Embed
                gridColumn={bp("1/end", "2/14")}
                code={video}
                responsive
              />
            </MaxWidthGrid>
            <MaxWidthGrid pt="3rem">
              <Box gridColumn={bp("1/end", "4/12")}>{children}</Box>
            </MaxWidthGrid>
          </>
        ) : (
            <MaxWidthGrid pt="7.5rem">
              <Box gridColumn={bp("1/end", "4/12")}>{children}</Box>
            </MaxWidthGrid>
        )}

      </Collapse>
    </Box>
  )
}
