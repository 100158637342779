import { graphql } from "gatsby"

import AccessoryProductList from "../views/AccessoryProductList"

export const query = graphql`
  query AccessoryProductList($pageSlug: String!, $accessorySlugs: [String]!) {
    collection: contentfulAccessoryCollectionType(
      internalTitle: { eq: $pageSlug }
    ) {
      seoTitle
      seoDescription {
        seoDescription
      }
      seoContentBlock {
        raw
      }
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
    }
    accessories: allContentfulAccessoryModel(
      filter: { internalTitle: { in: $accessorySlugs }, hidden: { ne: true } }
    ) {
      edges {
        node {
          type
          id
          title
          internalTitle
          productListImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          variants {
            id
            shopifyId
            color {
              name
              hexCode
            }
          }
        }
      }
    }
    sortedAccessories: contentfulCollection(internalTitle: { eq: $pageSlug }) {
      internalTitle
      accessories {
        type
        id
        title
        internalTitle
        productListImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        variants {
          id
          shopifyId
          color {
            name
            hexCode
          }
        }
      }
    }
  }
`
export default AccessoryProductList
