import React from "react"
import { Flex, Box, PseudoBox, PseudoBoxProps } from "@chakra-ui/core"
import GatsbyImage, { FluidObject } from "gatsby-image"
import styled from "@emotion/styled"

import Body from "../typography/Body"

import { placeholderImage } from "../../utils"
import { getFixedPrice } from "../Cart/utils"
import BackgroundImage from "../BackgroundImage"

interface AccessoryProductCardProps {
  image?: FluidObject | null
  title: string
  price?: string
  varaintCount?: number
}

const StyledImage = styled(GatsbyImage)`
  border-radius: 0.5rem;
  margin-bottom: 1.6875rem;
  height: 13.125rem;
  width: 100%;
`

const AccessoryProductCard: React.FC<
  PseudoBoxProps & AccessoryProductCardProps
> = ({ image, title, price, varaintCount, ...props }) => {
  return (
    <PseudoBox
      borderRadius="0.5rem"
      cursor="pointer"
      _hover={{ bg: "dividerLine" }}
      paddingX="1rem"
      paddingY="0.5rem"
      backgroundColor="white"
      overflow="hidden"
      boxShadow="big"
      {...props}
    >
      <Flex
        flexDir="column"
        h="20.75rem"
        alignItems="center"
        justifyContent="center"
      >
        <BackgroundImage
          fluid={image || placeholderImage}
          borderRadius="0.5rem"
          overflow="hidden"
          h="13.125rem"
          w="100%"
          mb="1.6875rem"
          backgroundSize={["contain", null, null, null, "cover"]}
        />
        <Box textAlign="center" px="1.25rem">
          <Body fontWeight="bold" color="night" mb="0.6875rem">
            {title}
          </Body>
          <Body color="dawn">{`${getFixedPrice(price)}, ${
            varaintCount ?? 1
          } Option${(varaintCount ?? 0) > 1 ? "s" : ""}`}</Body>
        </Box>
      </Flex>
    </PseudoBox>
  )
}

export default AccessoryProductCard
